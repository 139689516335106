import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import erobotphase from "../../images/EROBOT-phase.jpg"
import "../../components/prizes.css"
import "../../components/media.css"
// images
import { StaticImage } from "gatsby-plugin-image"

const Erobot = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">E-ROBOT Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The American-Made Buildings Prize: Envelope Retrofit
                Opportunities for Building Optimization Technologies (E-ROBOT)
                is designed to catalyze the development of minimally invasive,
                low-cost, and holistic building envelope retrofit solutions that
                make retrofits easier, faster, safer, and more accessible for
                workers.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/EROBOT">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="lazy"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">E-ROBOT Prize</h1>
          <p className="pcol-fz">
            As part of the American-Made Challenges series, the E-ROBOT Prize
            unites the world’s best-in-class research base with the unparalleled
            entrepreneurial support system of the American-Made Network.
            Consisting of pioneering makerspaces, dozens of energy incubators,
            universities, and 17 U.S. Department of Energy (DOE) national
            laboratories, the Network is primed to create a sweeping portfolio
            of innovations to demonstrate the promise of advanced robotics for
            building envelope retrofits.
          </p>
          <p className="pcol-fz">
            Advancements in robotics allow workers to reach places or perform
            activities that were previously impossible. For example, robots can
            safely enter small spaces and cavities, such as ductwork, to perform
            air-sealing or other efficiency activities. Robots can complement
            and support the existing workforce by creating new employment and
            business opportunities while also helping to ensure quality and
            consistency when installing energy efficiency measures.
          </p>
        </div>
        <div className="container">
          <h1 className="mac_h1">The Prize Structure</h1>
          <div>
            <div className="row rww">
              <div className="col">
                <p className="pcol-fz">
                  The E-ROBOT Prize is made up of two phases that will
                  fast-track efforts to identify, develop, and validate
                  disruptive solutions to meet building industry needs. Each
                  phase will include a contest period when participants will
                  work to rapidly advance their solutions. DOE invites anyone,
                  individually or as a team, to compete to transform a
                  conceptual solution into product reality.
                </p>
                <button type="button" className="btns" disabled>
                  Learn about competing in the E-ROBOT Prize{" "}
                </button>
              </div>
              <div className="col">
                <StaticImage
                  src="../../images/EROBOT-phase.jpg"
                  className="img-styl"
                  alt="erobot phase"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <h2> Phase 1</h2>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Submission Open: November 16, 2020</li>
              <li>Submission Close: May 12, 2021, 5 p.m. ET</li>
              <li>Winner Notification: July 2021 (anticipated)</li>
            </ul>
            <hr />
            <h2> Phase 1</h2>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>Submission Open: July 2021 (anticipated)</li>
              <li>Submission Close: January 2022 (anticipated)</li>
              <li>Winner Notification: March 2022 (anticipated)</li>
            </ul>
            <hr />
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/EROBOT"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/EROBOT">
              <button type="button" className="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default Erobot
